<template>
  <div>
    <label class="block text-sm font-medium leading-6 text-slate-500 mb-2">
      <slot name="label" />
    </label>
    <div class="relative rounded-md shadow-sm">
      <div
        class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3 text-slate-500"
      >
        <slot name="icon-right" />
      </div>
      <RkInput
        v-if="props.type !== 'textarea'"
        ref="inputTemplateRef"
        v-model="model"
        :type="type"
        step=".01"
        class="text-base"
        v-bind="$attrs"
        @blur="$emit('blur')"
      ></RkInput>
      <textarea
        v-else
        ref="inputTemplateRef"
        v-model="model"
        class="appearance-none block w-full rounded-md border-0 py-2.5 pl-4 pr-9 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-300 text-base sm:text-sm sm:leading-6"
        v-bind="$attrs"
        @blur="$emit('blur')"
      />
    </div>
    <div v-if="hasErrors" class="text-red-500">
      <div
        v-for="error of errors"
        :key="error.$uid"
        class="input-errors mt-1.5"
      >
        <div class="error-msg">{{ error.$message }}</div>
      </div>
    </div>
    <div class="mt-1.5 text-slate-500">
      <slot name="assistance" />
    </div>
  </div>
</template>
<script lang="ts">
// use normal <script> to declare options
export default {
  inheritAttrs: false,
}
</script>
<script lang="ts" setup>
const props = defineProps<{
  errors?: any[]
  type?: string
}>()

const model = defineModel()

const inputTemplateRef = ref()
const focus = () =>
  props.type !== 'textarea'
    ? inputTemplateRef.value?.inputRef?.focus()
    : inputTemplateRef.value?.focus()
defineExpose({
  focus,
})

const hasErrors = computed(() => Boolean(props.errors?.length > 0))
</script>
