<script setup lang="ts">
import type { HTMLAttributes } from 'vue'
import { useVModel } from '@vueuse/core'
import { cn } from '@/lib/utils'

const props = defineProps<{
  class?: HTMLAttributes['class']
}>()

const model = defineModel()
const inputRef = ref<HTMLInputElement | null>(null)
defineExpose({
  inputRef,
})
</script>

<template>
  <input
    v-model="model"
    ref="inputRef"
    :class="
      cn(
        'flex h-10 w-full rounded-md border border-slate-200 bg-white py-[1.4rem] pl-4 pr-9 text-sm ring-offset-white file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-slate-500 focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-slate-950 focus-visible:ring-offset-1 disabled:cursor-not-allowed disabled:opacity-50 dark:border-slate-800 dark:bg-slate-950 dark:ring-offset-slate-950 dark:placeholder:text-slate-400 dark:focus-visible:ring-slate-300',
        props.class,
      )
    "
  />
</template>
